import { Banner, CheckBox, Link, Modal, SWAPSpace, Typography } from '@yosgo/swap-ui'
import React, { useEffect, useState } from 'react'
import { GetMember, JoinCaseGroupFromSOHO } from '../../../utils/AppRequest'
import PathAndTitle from '../../../utils/PathAndTitle'
import { useRouter } from 'next/router'
import { useMediaQuery } from '@material-ui/core'

interface JoinModalComponentProps {
  groupId: string,
  open: boolean;
  onSuccess?: () => void;
  onClose: () => void;
}

const JoinModalComponent = ({
    groupId,
    open,
    onSuccess,
    onClose,
  }: JoinModalComponentProps) => {
  const router = useRouter()
  const match768 = useMediaQuery('(min-width:768px)')

  const [isValidatedMember, setIsValidatedMember] = useState(false)
  const [isAgree, setIsAgree] = useState(false)
  const [isOpenHintModal, setIsOpenHintModal] = useState(false)

  useEffect(() => {
    setIsAgree(false)
  }, [open])

  useEffect(() => {
    handleGetMember()
  }, [])

  const handleGetMember = async () => {
    try {
      const GetMemberResult: any = await GetMember(`payload{
        member{
          id
          verified_state
        }
      }`)
      if (GetMemberResult && GetMemberResult.errors === null) {
        const data = GetMemberResult.payload.member
        setIsValidatedMember(data?.verified_state === 'CONFIRM')
      }
    } catch (err) {
      console.log("查詢會員資料時發生錯誤，請聯繫客服");
    }
  }

  const handleJoinGroup = async () => {
    if (!isValidatedMember) {
      setIsOpenHintModal(true)
      return;
    }
    const JoinCaseGroupFromSOHOResult: any = await JoinCaseGroupFromSOHO({ id: groupId })
    try {
      if (JoinCaseGroupFromSOHOResult && JoinCaseGroupFromSOHOResult.errors === null) {
        if (onSuccess) {
          onSuccess()
        }
      }
    } catch (err) {
      console.log("加入社團出現問題，請聯繫客服");
    }
  }

  const handleGoToVerificationPage = async () => {
    await router.push(PathAndTitle.member.settings.path)
  }

  return (
    <Modal
      titleStyle={{ padding: match768 ? '18px 24px' : '12px 16px' }}
      bodyPadding={match768 ? '24px' : '16px'}
      fullWidth={!match768}
      open={open}
      width={640}
      title={
        <Typography variant={match768 ? 'h6' : 'title'} color="black1000">
          加入 SWAP 官方社團
        </Typography>
      }
      primaryButton={{
        title: '我要加入',
        onClick: async () => {
          await handleJoinGroup()
        },
        disabled: !isAgree
      }}
      secondaryButton={{
        title: '取消',
        onClick: () => {
          onClose()
        }
      }}
      onClose={onClose}
    >
      {!isValidatedMember ? (
        <>
          <Banner>
            請先完成實名認證，才能加入社團。
            <Link
              onClick={() => router.push(PathAndTitle.member.settings.path)}
              variant="caption"
              color="primary400"
            >
              點擊前往認證
            </Link>
          </Banner>
          <SWAPSpace size={16} />
        </>
      ) : null}
      <Typography variant="title" color="black1000">管理員建立的社團公約</Typography>
      <SWAPSpace size={16} />
      <ol style={{ paddingInlineStart: 20, margin: 0 }}>
        <Typography variant="subtitle" color="black800">
          <li>
            維護社群安全
            <SWAPSpace size={4} />
            <Typography variant="body2" color="black700">加入社團前請先完成實名認證，藉此維護社團的品質與安全性，避免詐騙與廣告帳號。</Typography>
          </li>
        </Typography>
        <SWAPSpace size={8} />
        <Typography variant="subtitle" color="black800">
          <li>
            更新接案履歷
            <SWAPSpace size={4} />
            <Typography variant="body2" color="black700">報名任務前請填寫完整的接案履歷，可以幫助我們更了解您。</Typography>
          </li>
        </Typography>
        <SWAPSpace size={8} />
        <Typography variant="subtitle" color="black800">
          <li>
            名額有限
            <SWAPSpace size={4} />
            <Typography variant="body2" color="black700">我們無法保證所有人都能順利承接任務，但我們一定會仔細看過每一位報名者的資料，未來仍有機會與您合作。</Typography>
          </li>
        </Typography>
        <SWAPSpace size={8} />
        <Typography variant="subtitle" color="black800">
          <li>
            建立友善社群
            <SWAPSpace size={4} />
            <Typography variant="body2" color="black700">我們歡迎社團會員互相認識&合作。但若有不當的行為請聯繫我們，經調查屬實將移除惡意帳號且終身不得再註冊。</Typography>
          </li>
        </Typography>
      </ol>
      <SWAPSpace size={16} />
      <CheckBox
        style={{ marginLeft: 3 }}
        onChange={() => setIsAgree(!isAgree)}
        label={
          <Typography variant="body2" color="black1000">
            我同意並願意遵守「SWAP 官方社團」的社團公約
          </Typography>
        }
      />

      {/* Modal*/}
      <Modal
        open={isOpenHintModal}
        mobile={!match768}
        disCloseIcon={!match768}
        width={640}
        title={
          match768 ? (
            <Typography variant="h6" color="black800">
              尚未完成實名認證
            </Typography>
          ) : (
            <>
              <Typography variant="title" color="black1000">
                尚未完成實名認證
              </Typography>
              <SWAPSpace size={4} />
              <Typography variant="body2" color="black800">
                您目前尚未完成實名認證，因此無法加入社團。
              </Typography>
            </>
          )
        }
        primaryButton={{
          title: '前往實名認證',
          onClick: async () => {
            await handleGoToVerificationPage()
          },
        }}
        secondaryButton={{
          title: '取消',
          onClick: () => {
            setIsOpenHintModal(false)
          }
        }}
        onClose={() => setIsOpenHintModal(false)}
      >{match768 ? (
        <Typography variant="body2" color="black800">
          您目前尚未完成實名認證，因此無法加入社團。
        </Typography>
      ) : null}
      </Modal>
    </Modal>
  )
}

export default JoinModalComponent
