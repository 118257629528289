import {
  makeStyles,
} from '@material-ui/core'
import { Tooltip, Typography } from '@yosgo/swap-ui'

const useStyles = makeStyles({
  container: {
    position: 'relative',
    borderRadius: 12,
    marginRight: 8
  },
  iconMember: {
    width: 24,
    height: 24,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  iconValidation: {
    position: 'absolute',
    right: 4,
    bottom: 0,
  },
  iconLetter: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
})

export interface AvatarData {
  id: string;
  tooltipText: string;
  avatarUrl: string;
}
interface AvatarComponentProps {
  data?: AvatarData;
  size?: number | string;
  noValidation?: boolean
}

/*
 * 沒有 data 會顯示灰色的空頭像
 * 有 data 要看有沒有 avatarUrl，沒有的話顯示黑底與名字的第一個字
 */
const AvatarComponent = ({
    data,
    size = 40,
    noValidation = false
  }: AvatarComponentProps) => {
  const classes = useStyles()

  const title = data ? data.tooltipText ? data.tooltipText : '' : ''
  return (
    <Tooltip
      title={title}
      placement="top"
      arrow
    >
      <div
        className={classes.container}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: data ? '#2D2D2D' : '#ECECEC',
          backgroundImage: data && data.avatarUrl ? `url(${data.avatarUrl})` : 'none',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center'
        }}
      >
        {data && !data.avatarUrl ? (
          <span className={classes.iconLetter}>
            <Typography variant="h4" color="white">
              {title ? title[0].toUpperCase() : ''}
            </Typography>
          </span>
        ) : null}
        {!data ? (
          <span className={classes.iconMember}>
            {icon_member}
          </span>
        ) : null}
        {data && !noValidation ? (
          <span className={classes.iconValidation}>
            {icon_validation}
          </span>
        ) : null}
      </div>
    </Tooltip>
  )
}

const icon_member = (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M12 19.2C9.5 19.2 7.29 17.92 6 16C6.03 14 10 12.9 12 12.9C14 12.9 17.97 14 18 16C16.71 17.92 14.5 19.2 12 19.2ZM12 5C12.7956 5 13.5587 5.31607 14.1213 5.87868C14.6839 6.44129 15 7.20435 15 8C15 8.79565 14.6839 9.55871 14.1213 10.1213C13.5587 10.6839 12.7956 11 12 11C11.2044 11 10.4413 10.6839 9.87868 10.1213C9.31607 9.55871 9 8.79565 9 8C9 7.20435 9.31607 6.44129 9.87868 5.87868C10.4413 5.31607 11.2044 5 12 5ZM12 2C10.6868 2 9.38642 2.25866 8.17317 2.7612C6.95991 3.26375 5.85752 4.00035 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 6.47 17.5 2 12 2Z" fill="#CCCCCC"/>
  </svg>
)
const icon_validation = (
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g clip-path="url(#clip0_383_3958)">
      <rect x="3.5" y="4.08337" width="7" height="5.83333" fill="white"/>
      <path d="M13.4168 6.99998L11.9935 5.37832L12.1918 3.23165L10.086 2.75332L8.9835 0.898315L7.00016 1.74998L5.01683 0.898315L3.91433 2.75332L1.8085 3.22582L2.00683 5.37248L0.583496 6.99998L2.00683 8.62165L1.8085 10.7741L3.91433 11.2525L5.01683 13.1075L7.00016 12.25L8.9835 13.1016L10.086 11.2466L12.1918 10.7683L11.9935 8.62165L13.4168 6.99998ZM5.8335 9.91665L3.50016 7.58332L4.32266 6.76082L5.8335 8.26582L9.67766 4.42165L10.5002 5.24998L5.8335 9.91665Z" fill="#4862CC"/>
    </g>
    <defs>
      <clipPath id="clip0_383_3958">
        <rect width="14" height="14" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

export default AvatarComponent
