import {
  makeStyles, useMediaQuery
} from '@material-ui/core'
import { Modal, SWAPSpace, Typography } from '@yosgo/swap-ui'
import Avatar, { AvatarData } from './Avatar'
import React from 'react'

const useStyles = makeStyles({
  content: {},
  item: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: 8,
    borderRadius: 12,
    border: '1px solid #ECECEC',
  },
  displayFlexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  }
})
export interface HiredItem {
  id: string;
  name: string;
  role_name: string;
  picture: string;
}
interface HiredMoreModalComponentProps {
  data: HiredItem[];
  open: boolean;
  onClose: () => void;
}

const HiredMoreModalComponent = ({
   data,
   open,
   onClose,
  }: HiredMoreModalComponentProps) => {
  const classes = useStyles()
  const match768 = useMediaQuery('(min-width:768px)')

  return (
    <Modal
      titleStyle={{ padding: match768 ? '18px 24px' : '12px 16px' }}
      bodyPadding={match768 ? '24px' : '16px'}
      fullWidth={!match768}
      open={open}
      onClose={onClose}
      width={640}
      title={
        <Typography variant={match768 ? 'h6' : 'title'} color="black1000">
          已招募工作者
        </Typography>
      }
      maxWidth={640}
      height={480}
    >
      <div className={classes.content}>
        {data.map((item: HiredItem, index) => {
          const _avatarData: AvatarData = {
            id: item.id,
            tooltipText: `${item.name} | ${item.role_name}`,
            avatarUrl: item.picture
          }
          return (
            <div key={item.id}>
              <div className={classes.item}>
                <Avatar data={_avatarData} size={40} noValidation />
                <div style={{ marginLeft: 4 }}>
                  <Typography variant="body2" color="black1000">
                    {item.role_name}
                  </Typography>
                  <div className={classes.displayFlexStart}>
                    <Typography variant="body2" color="black700" style={{ marginRight: 4 }}>
                      {item.name}
                    </Typography>
                    {icon_validation}
                  </div>
                </div>
              </div>
              {index <= data.length ? <SWAPSpace size={8}></SWAPSpace> : null}
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

const icon_validation = (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <rect x="3" y="3.5" width="6" height="5" fill="white"/>
    <path d="M11.5 6.00002L10.28 4.61002L10.45 2.77002L8.645 2.36002L7.7 0.77002L6 1.50002L4.3 0.77002L3.355 2.36002L1.55 2.76502L1.72 4.60502L0.5 6.00002L1.72 7.39002L1.55 9.23502L3.355 9.64502L4.3 11.235L6 10.5L7.7 11.23L8.645 9.64002L10.45 9.23002L10.28 7.39002L11.5 6.00002ZM5 8.50002L3 6.50002L3.705 5.79502L5 7.08502L8.295 3.79002L9 4.50002L5 8.50002Z" fill="#4862CC"/>
  </svg>
)
export default HiredMoreModalComponent
