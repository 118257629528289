import {
  Banner,
  CheckBox,
  Dropdown,
  MenuItem,
  Modal,
  SWAPSpace,
  TextField,
  Typography
} from '@yosgo/swap-ui'
import React, { useEffect, useState } from 'react'
import { CGIR } from './NewsBlock'
import { ApplyCaseGroupItemCandidateFromSOHO, GetMember } from '../../../utils/AppRequest'
import ResumeCard from './ResumeCard'
import { useMediaQuery } from '@material-ui/core'

interface ApplyModalComponentProps {
  caseId: string;
  roles: CGIR[];
  open: boolean;
  onSuccess?: () => void;
  onClose: () => void;
}
const ApplyModalComponent = ({
    caseId,
    roles,
    open,
    onSuccess,
    onClose,
  }: ApplyModalComponentProps) => {
  const match768 = useMediaQuery('(min-width:768px)')

  const [member, setMember] = useState<any>(null)
  const [isAgree, setIsAgree] = useState(false)
  const [isApplied, setIsApplied] = useState(false)
  const [applyCandidatePosition, setApplyCandidatePosition] = useState('-')
  const [applyCandidateComment, setApplyCandidateComment] = useState('')

  // Reset form when close modal
  useEffect(() => {
    if (!open) {
      setIsAgree(false)
      setIsApplied(false)
      setApplyCandidatePosition('-')
      setApplyCandidateComment('')
    } else {
      handleGetMember()
    }
  }, [open])

  const handleGetMember = async () => {
    const GetMemberResult: any = await GetMember(`payload{
        member{
          id
          picture
          bio
          verified_state
          bio
          description
          cover_picture
          nick_name
          personal_id
        }
      }`)
    if (GetMemberResult && GetMemberResult.errors === null) {
      const data = GetMemberResult.payload.member
      setMember(data)
    }
  }

  const handleApply = async () => {
    try {
      const _params = {
        case_group_item_id: caseId,
        case_group_item_role_id: applyCandidatePosition,
        comment: applyCandidateComment
      }
      const ApplyCaseGroupItemCandidateFromSOHOResult: any = await ApplyCaseGroupItemCandidateFromSOHO(_params)
      if (ApplyCaseGroupItemCandidateFromSOHOResult && ApplyCaseGroupItemCandidateFromSOHOResult.errors === null) {
        setIsApplied(true)
        if (onSuccess) {
          onSuccess()
        }
      }
    } catch (err) {
      console.log('報名專案出現問題，請聯繫客服')
    }
  }

  return (
    <Modal
      titleStyle={{ padding: match768 ? '18px 24px' : '12px 16px' }}
      bodyPadding={match768 ? '24px' : '16px'}
      fullWidth={!match768}
      open={open}
      width={640}
      title={
        <Typography variant={match768 ? 'h6' : 'title'} color="black1000">
          立即報名
        </Typography>
      }
      primaryButton={!isApplied ? {
        title: '確認報名',
        onClick: async () => {
          await handleApply()
        },
        disabled: !isAgree || !applyCandidatePosition
      } : {
        title: '完成',
        onClick: async () => {
          location.reload()
        }
      }}
      secondaryButton={!isApplied ? {
        title: '取消',
        onClick: () => {
          onClose()
        }
      } : undefined}
      onClose={onClose}
    >
      {!isApplied ? (
        <>
          <Banner>
            請在報名前建立好您的接案履歷，讓案主了解您的接案相關經歷，以提升您的報名成功率！
          </Banner>
          <SWAPSpace size={16} />
          <Typography variant="title" color="black1000">自由工作者系列專訪  Vol.2</Typography>
          <SWAPSpace size={16} />
          <Dropdown
            height={56}
            onChange={(e: any) => setApplyCandidatePosition(e.target.value)}
            value={applyCandidatePosition}
            fullWidth
          >
            <MenuItem
              disabled
              height={36}
              style={{
                backgroundColor: 'white',
                color: '#4862CC',
                display: 'flex',
                flexWrap: 'nowrap',
                justifyContent: 'space-between',
                opacity: 1,
              }}
              value={'-'}
            >
              請選擇報名職位
            </MenuItem>
            {roles ? roles.map(
              (role: CGIR, index) => {
                return (
                  <MenuItem
                    key={index}
                    height={36}
                    style={{
                      backgroundColor: undefined,
                      color: undefined,
                      display: 'flex',
                      flexWrap: 'nowrap',
                      justifyContent: 'space-between',
                      opacity: undefined,
                    }}
                    value={role.id}
                  >
                    {role.role_name}
                  </MenuItem>
                )
              }
            ) : null}
          </Dropdown>
          <SWAPSpace size={16} />
          <TextField
            label="備註"
            placeholder="告訴社團主你想說的話、合作習慣或是你的報價"
            height={150}
            multiline
            fullWidth
            rows={5}
            value={applyCandidateComment}
            onChange={(e) => setApplyCandidateComment(e.target.value)}
          />
          <SWAPSpace size={16} />
          <CheckBox
            style={{ marginLeft: 3 }}
            onChange={() => setIsAgree(!isAgree)}
            label={
              <Typography variant="body2" color="black1000">
                附上我的接案履歷，供案主瀏覽我的相關經歷
              </Typography>
            }
          />
          <SWAPSpace size={16} />
          <ResumeCard data={member} />
        </>
      ) : (
        <>
          <div style={{
            width: 168,
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <img src={require('./assets/qrcode.png').default.src} width={120} height={120} alt='' />
            <a href="https://lin.ee/mb2hNsC" style={{
              cursor: 'pointer',
              outline: 'none',
              textDecoration: 'none',
              boxSizing: 'border-box',
              fontWeight: 700,
              lineHeight: 1.4,
              borderRadius: 4
            }}>
              <Typography variant="caption1" color="primary400">https://lin.ee/mb2hNsC</Typography>
            </a>
            <SWAPSpace size={16} />
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
              {icon_success}
              <Typography variant="title" color="black1000" style={{ marginLeft: 4 }}>您已成功報名任務！</Typography>
            </div>
          </div>
          <SWAPSpace size={8} />
          <Typography variant="body2" color="black700" style={{ textAlign: 'center' }}>請掃以下 QR Code 加入 SWAP 接案合作站，後續的任務接洽和溝通會在此進行。</Typography>
        </>
      )}
    </Modal>
  )
}

const icon_success = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <path d="M10.0003 1.66669C5.41699 1.66669 1.66699 5.41669 1.66699 10C1.66699 14.5834 5.41699 18.3334 10.0003 18.3334C14.5837 18.3334 18.3337 14.5834 18.3337 10C18.3337 5.41669 14.5837 1.66669 10.0003 1.66669ZM8.33366 14.1667L4.16699 10L5.34199 8.82502L8.33366 11.8084L14.6587 5.48335L15.8337 6.66669L8.33366 14.1667Z" fill="#00821E"/>
  </svg>
)

export default ApplyModalComponent
